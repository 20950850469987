<template>
  <div class="m-stack__item m-stack__item--middle m-brand__tools">
    <a
      id="m_aside_left_minimize_toggle"
      class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
      href="#"
      @click.prevent="toggleAsideMenu"
    >
      <span />
    </a>
    <a
      id="m_aside_left_offcanvas_toggle"
      class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
      href="#"
      @click.prevent="toggleAsideMenu"
    >
      <span />
    </a>
  </div>
</template>

<script>
import { mobileMixin } from '@/mixins/mobileMixin';

export default {
  name: "MenuToggler",
  mixins: [
    mobileMixin
  ],
  metaInfo () {
    return {
      bodyAttrs: {
        class: this.createBodyClass
      }
    }
  },
  data () {
    return {
      classBody: "m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-light m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default",
      boolActiveMenu: true
    }
  },
  computed: {
    createBodyClass: function () {
      if (this.boolActiveMenu) {
        return this.classBody;
      }
      if (this.mobileMixin_isMobile == true || window.innerWidth < 1025) {
        return this.classBody + ' m-aside-left--on mobileAsideMenu';
      } else {
        return this.classBody + ' m-aside-left--minimize m-aside-left--on';
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleResize);
    this.$eventBus.$on('toggleAsideMenu', this.toggleAsideMenu);
    this.$eventBus.$on('toggleAsideMenuMobile', this.toggleAsideMenuMobile);

    if (this.mobileMixin_isMobile == true) {
      this.classBody += ' mobileAsideMenu';
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('toggleAsideMenu');
    this.$eventBus.$off('toggleAsideMenuMobile');
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    toggleAsideMenuMobile () {
      if (this.mobileMixin_isMobile == true || window.innerWidth < 1025) {
        this.toggleAsideMenu();
      }
    },
    toggleAsideMenu () {
      this.boolActiveMenu = !this.boolActiveMenu;
    },
    handleResize () {
      this.boolActiveMenu = true;
    }
  }
}
</script>
